import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import api from "../api/api";
import { toast } from "react-toastify";

const CourseModal = (props) => {
  console.log("props", props);
  const [semester, setSemester] = useState("");
  const [level, setLevel] = useState("");
  const [code, setCode] = useState("");
  const [title, setTitle] = useState("");
  const [unit, setUnit] = useState("");
  const [loading, setLoading] = useState(false);

  const addCourse = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await api.post("/courses/staff/create/", {
        code,
        title,
        unit,
        level,
        semester,
        semester_int:
          semester.toLowerCase().includes("first")
            ? "1"
            : semester.toLowerCase().includes("second")
            ? "2"
            : "",
      });
      toast.success("Course created");
      setLoading(false);
      props.handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updateCourse = async (id) => {
    setLoading(true);
    try {
      const { data } = await api.put(`/courses/staff/update/${id}`, {
        code,
        title,
        unit,
        level,
        semester,
        semester_int:
          semester.toLowerCase().includes("first")
            ? "1"
            : semester.toLowerCase().includes("second")
            ? "2"
            : "",
      });
      toast.success("Course edited");
      setLoading(false);
      props.getCourses();
      props.handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Update state with props values when updateData changes
    setSemester(props.semester);
    setLevel(props.level);
    setCode(props.code);
    setTitle(props.title);
    setUnit(props.unit);
  }, [props]);
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {JSON.stringify(props.title) ? "Edit" : "Create"} course
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addCourse}>
            <div class="form-group row">
              <div class="col-md-12">
                <label>Course code</label>
                <input
                  class="form-control mb-3"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>
              <div class="col-md-12">
                <label>Course title</label>
                <input
                  class="form-control mb-3"
                  type="text"
                  value={title}
                  placeholder={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>
              <div class="col-md-12">
                <label>Units</label>
                <input
                  class="form-control mb-3"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                  required
                />
              </div>
              <div class="col-md-12">
                <label>Level</label>
                <select
                  class="form-control mb-3"
                  value={level}
                  onChange={(e) => setLevel(e.target.value)}
                  required
                >
                  <option></option>
                  <option>100</option>
                  <option>200</option>
                  <option>300</option>
                  <option>400</option>
                  <option>500</option>
                </select>
              </div>
              <div class="col-md-12">
                <label>Enter semester</label>
                <select
                  class="form-control mb-3"
                  value={semester}
                  onChange={(e) => setSemester(e.target.value)}
                  required
                >
                  <option value=""></option>
                  <option value="first semester">First semester</option>
                  <option value="second semester">Second semester</option>
                </select>
              </div>
              <div class="col-md-12">
                {props?.title ? (
                  <button
                    type="button"
                    className="btn btn-warning waves-effect mr-2"
                    disabled={loading}
                    onClick={() => updateCourse(props?.id)}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect mr-2"
                    disabled={loading}
                  >
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="btn waves-effect"
                  onClick={props.handleClose}
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CourseModal;
